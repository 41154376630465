import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
import store from "@/store/index.js";

export default {
  data: () => ({
    emailCampaignList: [],
    selected: "",
    selectedId: 0,
    selectedRows: [],
    showLoader: false,
    searchText: "",
    showToggleTestPopup: false,
    tested_id: "",
    testedEmailsList: "",
    testDisable: false,
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),

  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },

  computed: {
    selectAllList() {
      if (this.emailCampaignList.length > 0) {
        return (
          _.where(this.emailCampaignList, { isSelected: true }).length ===
          this.emailCampaignList.length
        );
      }
      return false;
    },

    hasEmailCampaignTempletShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasEmailCampaignTempletAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasEmailCampaignTempletDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasEmailCampaignTempletUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },

  methods: {
    convertToEDT(selectedTime) {
      // if (!selectedTime || typeof selectedTime !== 'string') return ''; // Handle null, undefined, or non-string cases

      const [hours, minutes] = selectedTime.split(":").map(Number);
      const selectedDate = new Date();
      selectedDate.setUTCHours(hours);
      selectedDate.setUTCMinutes(minutes);
      const selectedTimeZoneOffset =
        selectedDate.getTimezoneOffset() * 60 * 1000;
      const edtOffset = -4 * 60 * 60 * 1000; // Convert hours to milliseconds
      const edtTime = new Date(
        selectedDate.getTime() + selectedTimeZoneOffset + edtOffset
      );
      return edtTime.toISOString().slice(11, 16);
    },
    checkUncheckedList(event) {
      this.emailCampaignList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    changeStatus(emailCampaign, event) {
      let _vm = this;
      let _msg =
        emailCampaign.running == 1
          ? "Are you sure you want to Paused this Campaign?"
          : "Are you sure you want to Running this Campaign?";
      // let _status = emailCampaign.status == true ? 'Running' : 'Paused';
      this.$dialog
        .confirm(_msg)
        .then(function () {
          _vm.axios
            .get("/campaigns-status/" + emailCampaign.id)
            .then(function () {
              _vm.getAll();
            })
            .catch(function () {
              document.getElementById(
                "emailCampaign-" + emailCampaign.id
              ).checked = emailCampaign.running;
            });
        })
        .catch(function () {
          document.getElementById("emailCampaign-" + emailCampaign.id).checked =
            emailCampaign.running;
        });
    },
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        this.emailCampaignList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      _vm.axios
        .post("/campaigns-deletes", { ids: ids })
        .then(function () {
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {});
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },

    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    search() {
      this.getAll();
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      this.axios
        .get("/campaigns" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.emailCampaignList = response.data.data.data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
    openTestToggle(id) {
      let _vm = this;
      _vm.tested_id = id;
      _vm.showToggleTestPopup = true;
    },
    closeShowToggleTestPopup() {
      let _vm = this;
      _vm.tested_id = "";
      _vm.testedEmailsList = "";
      _vm.showToggleTestPopup = false;
    },
    totalRemainingEmailCount(emailCampaign) {
      let totalCount = 0;
      if (
        emailCampaign.audience_remaining_email_ids &&
        emailCampaign.audience_remaining_old_email_ids
      ) {
        let newEmailCount =
          emailCampaign.audience_remaining_email_ids.ids.length;
        let oldEmailCount =
          emailCampaign.audience_remaining_old_email_ids.old_ids.length;
        totalCount += newEmailCount + oldEmailCount;
      }
      if (
        emailCampaign.audience_remaining_agent_email_ids &&
        Array.isArray(emailCampaign.audience_remaining_agent_email_ids.ids) &&
        emailCampaign.audience_remaining_agent_old_email_ids &&
        Array.isArray(
          emailCampaign.audience_remaining_agent_old_email_ids.old_ids
        )
      ) {
        emailCampaign.audience_remaining_agent_old_email_ids.old_ids.forEach(
          (item) => {
            if (Array.isArray(item.old_ids)) {
              totalCount += item.old_ids.length;
            }
          }
        );
        emailCampaign.audience_remaining_agent_email_ids.ids.forEach((item) => {
          if (Array.isArray(item.ids)) {
            totalCount += item.ids.length;
          }
        });
      }

      return totalCount;
    },
    totalRemainingSentEmailCount(emailCampaign, index) {
      let totalCount = 0;
      if (
        emailCampaign.audience_sent_email_ids &&
        emailCampaign.audience_sent_old_email_ids
      ) {
        let newEmailCount = emailCampaign.audience_sent_email_ids.ids.length;
        let oldEmailCount =
          emailCampaign.audience_sent_old_email_ids.old_ids.length;
        totalCount += newEmailCount + oldEmailCount;
      }
      if (
        emailCampaign.audience_sent_agent_email_ids &&
        Array.isArray(emailCampaign.audience_sent_agent_email_ids.ids) &&
        emailCampaign.audience_sent_agent_old_email_ids &&
        Array.isArray(emailCampaign.audience_sent_agent_old_email_ids.old_ids)
      ) {
        emailCampaign.audience_sent_agent_old_email_ids.old_ids.forEach(
          (item) => {
            if (Array.isArray(item.old_ids)) {
              totalCount += item.old_ids.length;
            }
          }
        );
        emailCampaign.audience_sent_agent_email_ids.ids.forEach((item) => {
          if (Array.isArray(item.ids)) {
            totalCount += item.ids.length;
          }
        });
      }

      return totalCount;
    },
    totalRemainingFailedEmailCount(emailCampaign) {
      let totalCount = 0;
      if (
        emailCampaign.audience_failed_email_ids &&
        emailCampaign.audience_failed_old_email_ids
      ) {
        let newEmailCount = emailCampaign.audience_failed_email_ids.ids.length;
        let oldEmailCount =
          emailCampaign.audience_failed_old_email_ids.old_ids.length;
        totalCount += newEmailCount + oldEmailCount;
      }
      if (
        emailCampaign.audience_failed_agent_email_ids &&
        Array.isArray(emailCampaign.audience_failed_agent_email_ids.ids) &&
        emailCampaign.audience_failed_agent_old_email_ids &&
        Array.isArray(emailCampaign.audience_failed_agent_old_email_ids.old_ids)
      ) {
        emailCampaign.audience_failed_agent_old_email_ids.old_ids.forEach(
          (item) => {
            if (Array.isArray(item.old_ids)) {
              totalCount += item.old_ids.length;
            }
          }
        );
        emailCampaign.audience_failed_agent_email_ids.ids.forEach((item) => {
          if (Array.isArray(item.ids)) {
            totalCount += item.ids.length;
          }
        });
      }

      return totalCount;
    },
    storeTestedEmails() {
      let _vm = this;
      store.state.isLoaderShow = true;
      _vm.testDisable = true;
      let campaign_id = _vm.tested_id;
      let emails = _vm.testedEmailsList;
      _vm.axios
        .post("/campaigns-test", { campaign_id, emails })
        .then(function () {
          _vm.getAll();
          store.state.isLoaderShow = false;
          _vm.testDisable = false;
          _vm.tested_id = "";
          _vm.testedEmailsList = "";
          _vm.showToggleTestPopup = false;
        })
        .catch(function () {
          store.state.isLoaderShow = false;
          _vm.tested_id = "";
          _vm.testedEmailsList = "";
          _vm.testDisable = false;
        });
    },
    handleCheck(e) {
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          this.selectedRows = this.emailCampaignList.map(
            (item) => "checkbox" + item.id
          );
          this.emailCampaignList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          this.selectedRows = [];
          this.emailCampaignList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          this.selectedRows.push(e.target.id);
        } else {
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
